.collapsible-text {
    position: relative;
}

.collapsible-text-switch {
    display: block;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.collapsible-text {
    position: relative;
    padding-bottom: 60px; //for switch button to be located
    &.inactive {
        padding-bottom: 0;
    }
    overflow: hidden;

    .switch {
        position: absolute;
            
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
        bottom: 0;
        left: 0;

        width: 100%;
        height: 60px;

        .collapsible-text-switch {
            position: absolute;
            bottom: 0;
            right: 20px;
            display: inline-block;
            $color: white;
            padding: 10px 20px;
            border-radius: 7px;
            background-color: black;
            cursor: pointer;
            font-size: 14px;
            color: $color;
            &:hover, &:active, &:focus {
                outline: 0;
                color: $color;
            }
            
        }
    }
    &.collapsed {
//        padding-bottom: 0;        
    }
    
}
